<template>
  <div>
    <portal-target name="Breadcrumbs"></portal-target>
    <portal to="pageHeaderRight">
      <contact-dropdown/>
      <share-dropdown :subjectType="`page`" :subjectId="id"></share-dropdown>
      <router-button
        v-if="$store.state.is_godfather"
        :routeName="brandDescribeEditRoute"
        :routeParam="$route.params.pathName"
        :text="$t('app.edit_description')"
        icon="icon-icon-edit-pencil-line"
        cssClass="ab-button--white ab-button--edit-description"
      ></router-button>
      <router-button
        v-if="$store.state.is_brand_manager"
        :routeName="brandEditRoute"
        :routeParam="$route.params.pathName"
        :text="$t('app.edit')"
        icon="icon-edit"
        cssClass="ab-button--white ab-button--edit"
      ></router-button>

      <delete-button
        @delete="removePage($route.params.pathName)"
        v-if="$store.state.is_brand_manager"
      ></delete-button>
    </portal>

    <div class="grid-x grid-margin-x">
      <div class="cell medium-9">
        <view-content-repeater
          :content-items="brandData.value"
        ></view-content-repeater>
      </div>
      <div class="cell medium-3">
        <aside>
          <view-content-repeater
            :content-items="brandData.sidebar"
          ></view-content-repeater>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import ViewContentRepeater from '@/views/brand/components/ViewContentRepeater'

export default {
  name: 'BrandDetail',
  data () {
    return {
      title: '',
      id: null,
      brandData: {
        value: [],
        sidebar: [],
        id: null
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      backend
        .get(`${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}`)
        .then(response => {
          this.id = response.data.id
          this.brandData.value = response.data.value
          this.brandData.sidebar = response.data.sidebar
          this.brandData.id = response.data.id
          this.title = response.data.name
          document.title = 'ABRND | Merk - ' + response.data.name
        })
    },
    removePage (path) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_page'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.yes'),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/pages/${path}`)
                .then(() => {
                  this.$emit('updateBrandPages')
                  this.$notify({
                    group: 'custom-template',
                    title: false,
                    position: 'bottom left',
                    text: this.$t('app.page_removed')
                  })
                  this.$modal.hide('dialog')
                  this.$router.push({ name: this.brandListRoute })
                })
            }
          }
        ]
      })
    }
  },
  computed: {
    brandDescribeEditRoute: function () {
      if (this.$route.name === 'BrandGuidelineDetail') {
        return 'BrandGuidelineDescribeEdit'
      }
      return 'BrandDescribeEdit'
    },
    brandEditRoute: function () {
      if (this.$route.name === 'BrandGuidelineDetail') {
        return 'BrandGuidelineDetailEdit'
      }
      return 'BrandDetailEdit'
    },
    brandListRoute: function () {
      if (this.$route.name === 'BrandGuidelineDetail') {
        return 'BrandGuidelineList'
      }
      return 'BrandList'
    }
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  components: {
    ViewContentRepeater
  }

}
</script>
